<script lang="ts">
  // import Headr from "./components/Header.svelte";
  // import Sidebar from "./components/Sidebar.svelte";
  // import Footer from "./components/Footer.svelte";
  import Home from "./components/Home.svelte";
  import { Route, router } from "tinro";

  router.mode.hash();
</script>

<Route path="/">
  <Home />
</Route>

<style global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    color: white;
    background-color: #141414;
    font-family: "Source Code Pro", monospace;
    text-rendering: geometricPrecision;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  main::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  main {
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 11 */
  }
</style>
